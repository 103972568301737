import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

class ErrorPage extends React.Component {
    render() {
        const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
        const data = {
            hero: 'hero-home',
            size: 'is-medium',
            isHidden: true,
            text: 'something went wrong...',
            servicesNav
        } 

        return (
            <Layout location={this.props.location} data={data}>
                <Helmet>
                  <title>Page not found</title>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <div className="columns is-mobile is-centered">
                                <div className="column is-half">
                                    <h1>Page not found</h1>
                                    <p>Oops! The page you are looking for has been removed or relocated.</p>
                                    <Link to='/'>Go Back</Link>
                                </div>    
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default ErrorPage

export const pageQuery = graphql`
  query ErrorPage {
    site {
      siteMetadata {
        homePage {
          title
          description
          imageUrl
          siteUrl
        }
      }
    } 
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`